const currentUser = (currentUser = {}, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return action.payload
    case 'FETCH_USER':
      return action.payload
    default:
      return currentUser
  }
}
export default currentUser

import * as api from '../api'

//Action Creators
export const getCompany = () => async (dispatch) => {
  try {
    const { data } = await api.fetchCompany()
    dispatch({ type: 'FETCH_COMPANY_INFORMATION', payload: data })
  } catch (error) {
    console.log(error.message)
  }
}

import React from 'react'
import { Routes, Route } from 'react-router'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const Login = React.lazy(() => import('../Login/Login'))
const Dashboard = React.lazy(() => import('../Dashboard/Dashboard'))
const Properties = React.lazy(() => import('../Properties/Properties'))
const Property = React.lazy(() => import('../Properties/Property/Property'))
const Tenants = React.lazy(() => import('../Tenants/Tenants'))
const Tenancies = React.lazy(() => import('../Tenancies/Tenancies'))
const Notices = React.lazy(() => import('../Notices/Notices'))
const Maintenance = React.lazy(() =>
  import('../MaintenanceRequests/MaintenanceRequests')
)
const Incidents = React.lazy(() => import('../Incidents/Incidents'))
const Receipts = React.lazy(() => import('../Receipts/Receipts'))
const Expenses = React.lazy(() => import('../Expenses/Expenses'))
const Reports = React.lazy(() => import('../Reports/Reports'))
const PaymentSummary = React.lazy(() => import('../Reports/PaymentSummary'))
const MaintenanceReport = React.lazy(() =>
  import('../Reports/MaintenanceReport')
)
const ExpensesReport = React.lazy(() => import('../Reports/ExpensesReport'))
const Insights = React.lazy(() => import('../Reports/Insights'))
const Occupancy = React.lazy(() => import('../Reports/Occupancy'))
const Users = React.lazy(() => import('../Users/Users'))

const Router = () => {
  const loading = (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  )
  return (
    <React.Suspense fallback={loading}>
      <Routes>
        <Route exact path='/' element={<Dashboard />} />
        <Route path='properties' element={<Properties />} />
        <Route exact path='property/:id' element={<Property />} />
        <Route path='tenants' element={<Tenants />} />
        <Route path='tenancies' element={<Tenancies />} />
        <Route path='notices' element={<Notices />} />
        <Route path='maintenance' element={<Maintenance />} />
        <Route path='incidents' element={<Incidents />} />
        <Route path='receipts' element={<Receipts />} />
        <Route path='expenses' element={<Expenses />} />
        <Route path='reports' element={<Reports />} />
        <Route path='paymentsummary' element={<PaymentSummary />} />
        <Route path='maintenancereport' element={<MaintenanceReport />} />
        <Route path='insights' element={<Insights />} />
        <Route path='occupancy' element={<Occupancy />} />
        <Route path='expensesreport' element={<ExpensesReport />} />
        <Route path='users' element={<Users />} />
        <Route path='login' element={<Login />} />
      </Routes>
    </React.Suspense>
  )
}

export default Router

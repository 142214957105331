import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { styled /*useTheme*/ } from '@mui/material/styles'
import { useNavigate } from 'react-router'

import axios from '../../api/axios'
import sha1 from 'sha1'

import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ClearIcon from '@mui/icons-material/Clear'
import SettingsIcon from '@mui/icons-material/Settings'
import Slide from '@mui/material/Slide'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'

import { setToken } from '../../actions/token'
import {
  getCurrentUser,
  getCurrentUserRoles,
  setCurrentUser,
  setCurrentUserRoleNames,
} from '../../actions/currentUser'
import { setLoggedIn } from '../../actions/loggedIn'

import Login from '../Login/Login'
import AroPMISLogo from '../../images/aropmis_white.png'
import { NavItems } from './NavItems'
import { Link } from 'react-router-dom'
import Router from './Router'
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { setPageTitle } from '../../actions/pageTitle'
import { setAlertMessage } from '../../actions/alertMessage'
import { getRoleNames } from '../../global-functions'
import { getCompany } from '../../actions/company'
import { getCounties } from '../../actions/counties'
import { getUnits } from '../../actions/units'
import { getProperties } from '../../actions/properties'
import { getTenantACEntries } from '../../actions/tenantACEntries'
import { getTenancies } from '../../actions/tenancies'
import { getAllTenancies } from '../../actions/allTenancies'
import { getReceipts } from '../../actions/receipts'
import { getAllUnits } from '../../actions/allUnits'
import { getAllProperties } from '../../actions/allProperties'
import { getTenants } from '../../actions/tenants'
import { getRoles } from '../../actions/roles'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const DrawerNav = () => {
  // const theme = useTheme()

  const pageTitle = useSelector((state) => state.pageTitle)
  const subscription = useSelector((state) => state.subscription)
  const loggedIn = useSelector((state) => state.loggedIn)
  const company = useSelector((state) => state.company)
  const currentUser = useSelector((state) => state.currentUser)
  const roles = useSelector((state) => state.roles)
  const currentUserRoles = useSelector((state) => state.currentUserRoles)
  const currentUserRoleNames = useSelector(
    (state) => state.currentUserRoleNames
  )

  const alertMessage = useSelector((state) => state.alertMessage)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)

  const drawerRef = useRef(null)
  const handleClickOutsideDrawer = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setOpen(false)
      setShowDrawer(false)
    }
  }

  const checkLogin = () => {
    let token = localStorage.getItem('apmislltk')
    if (token !== null) {
      dispatch(setToken(token))
      axios
        .post('user/authenticate')
        .then((res) => {
          if (res.data.auth) {
            dispatch(setLoggedIn(true))
            dispatch(setCurrentUser(res.data.userData.user))
          } else {
            dispatch(setLoggedIn(false))
          }
        })
        .catch((err) => {
          dispatch(setLoggedIn(false))
        })
    }
  }

  //Alert Handlers
  const [openAlert, setOpenAlert] = useState(false)
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const [openProfile, setOpenProfile] = useState(false)
  const openProfileDialog = () => {
    setEmail(currentUser.email)
    setCurrentPassword('')
    setOpenProfile(true)
  }
  const closeProfileDialog = () => {
    setOpenProfile(false)
  }

  //Redictors
  const navigate = useNavigate()
  const routeChange = (path) => {
    navigate(path)
  }

  //User Profile setup
  const [email, setEmail] = useState(currentUser.email)
  const [changePassword, setChangePassword] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show)
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const [changes, setChanges] = useState(false)
  const [loading, setLoading] = useState(false)

  const emailRegExp = /^[a-zA-Z0-9\.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/

  const updateProfile = () => {
    setLoading(true)
    //validation
    if (currentPassword.trim().length === 0) {
      dispatch(
        setAlertMessage({
          message: 'Current Password not provided.',
          severity: 'error',
        })
      )
      setLoading(false)
      return
    }
    if (sha1(currentPassword.trim()) !== currentUser.password) {
      dispatch(
        setAlertMessage({
          message: 'Current Password provided is incorrect.',
          severity: 'error',
        })
      )
      setLoading(false)
      return
    }
    if (email.trim().length === 0) {
      dispatch(
        setAlertMessage({
          message: ' Email address not provided.',
          severity: 'error',
        })
      )
      setLoading(false)
      return
    }
    if (!emailRegExp.test(email)) {
      dispatch(
        setAlertMessage({
          message: ' Email address provided is not valid.',
          severity: 'error',
        })
      )
      setLoading(false)
      return
    }
    if (changePassword) {
      if (newPassword.trim().length === 0) {
        dispatch(
          setAlertMessage({
            message: ' New password is not provided.',
            severity: 'error',
          })
        )
        setLoading(false)
        return
      }
      if (newPassword.trim().length < 4) {
        dispatch(
          setAlertMessage({
            message: ' New password provided is too short.',
            severity: 'error',
          })
        )
        setLoading(false)
        return
      }
      if (sha1(newPassword.trim()) === currentUser.password) {
        dispatch(
          setAlertMessage({
            message: ' New password provided is same as the current password.',
            severity: 'error',
          })
        )
        setLoading(false)
        return
      }
      if (newPassword.trim() !== confirmPassword.trim()) {
        dispatch(
          setAlertMessage({
            message: ' New passwords provided do not match.',
            severity: 'error',
          })
        )
        setLoading(false)
        return
      }
    }

    let newDetails = {
      email: email,
    }
    if (changePassword) newDetails.password = sha1(newPassword.trim())
    if (currentUser.status === 'new') newDetails.status = 'active'
    axios
      .put(`user/${currentUser.id}`, { ...newDetails })
      .then((res) => {
        const cU = dispatch(getCurrentUser(currentUser.id))
        dispatch(setCurrentUser(cU))
        dispatch(
          setAlertMessage({ message: res.data.message, severity: 'success' })
        )
        setLoading(false)
        closeProfileDialog(true)
      })
      .catch((error) => {
        dispatch(setAlertMessage({ message: error.message, severity: 'error' }))
        setLoading(false)
      })
  }

  const updateSubscription = () => {
    let newSubscription = subscription
    newSubscription.clientID = currentUser.id
    axios
      .post('notification/client/update', { ...newSubscription })
      .then((res) => {
        // console.log(res.data.message)
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  useEffect(() => {}, [dispatch])

  useEffect(() => {
    dispatch(setPageTitle('Dashboard'))
  }, [])

  useEffect(() => {
    if (loggedIn) {
      dispatch(getCompany())
      dispatch(getCounties())
      dispatch(getUnits())
      dispatch(getProperties())
      dispatch(getTenantACEntries())
      dispatch(getTenancies())
      dispatch(getAllTenancies())
      dispatch(getReceipts())
      dispatch(getAllUnits())
      dispatch(getAllProperties())
      dispatch(getTenants())
      dispatch(getRoles())
      dispatch(getCurrentUserRoles(currentUser.id))
    }
  }, [loggedIn])

  useEffect(() => {
    if (currentUser.status && currentUser.status === 'new') {
      dispatch(
        setAlertMessage({
          message:
            'You are using the default password. Please change it before you proceed.',
          severity: 'info',
        })
      )
      setChangePassword(true)
      openProfileDialog()
    }
    if (currentUser.status && !currentUser.is_active) {
      dispatch(
        setAlertMessage({
          message:
            'Your account has been disabled. Contact the system administrator for assistance.',
          severity: 'error',
        })
      )
      routeChange('/login')
    }
    if (subscription.id) {
      updateSubscription()
    }
  }, [currentUser])

  useEffect(() => {
    dispatch(setCurrentUserRoleNames(getRoleNames(currentUserRoles, roles)))
  }, [currentUserRoles, roles])

  useEffect(() => {
    changePassword || currentUser.email !== email
      ? setChanges(true)
      : setChanges(false)
  }, [email, changePassword])

  useEffect(() => {
    if (alertMessage.message) {
      setOpenAlert(true)
    }
  }, [alertMessage])

  useEffect(() => {
    checkLogin()
  }, [loggedIn])

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDrawer, true)
    document.addEventListener('scroll', handleClickOutsideDrawer, true)
    return () => {
      document.removeEventListener('click', handleClickOutsideDrawer, true)
      document.removeEventListener('scroll', handleClickOutsideDrawer, true)
    }
  }, [])

  return (
    <>
      {!loggedIn ? (
        <Login />
      ) : (
        <Box sx={{ display: 'flex' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={openAlert}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
          >
            <Alert
              variant='filled'
              onClose={handleCloseAlert}
              severity={alertMessage.severity}
              sx={{ width: '100%' }}
            >
              {alertMessage.message}
            </Alert>
          </Snackbar>
          <CssBaseline />
          <AppBar position='fixed' open={open}>
            <Toolbar sx={{ display: 'flex' }}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={() => {
                  if (!showDrawer) {
                    setOpen(true)
                    setShowDrawer(true)
                  } else {
                    setOpen(false)
                    setShowDrawer(false)
                  }
                }}
                edge='start'
                sx={{
                  marginRight: 5,
                  // ...(open && { display: 'none' }),
                }}
              >
                {showDrawer ? (
                  <Tooltip title='Close Menu' arrow placement='right'>
                    <CloseRoundedIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title='Open Menu' arrow placement='right'>
                    <MenuIcon />
                  </Tooltip>
                )}
              </IconButton>
              <Typography variant='h6' noWrap component='div'>
                <Link
                  to='/'
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    dispatch(setPageTitle('Dashboard'))
                  }}
                >
                  <font style={{ color: '#FFAA00' }}>AroPMIS</font>{' '}
                </Link>
                | {pageTitle}
              </Typography>
              <Tooltip title=' Home' arrow>
                <IconButton
                  aria-label='Home'
                  sx={{ marginLeft: 'auto', color: '#FFAA00' }}
                  onClick={() => {
                    dispatch(setPageTitle('Dashboard'))
                    routeChange(`/`)
                  }}
                >
                  <HomeRoundedIcon />
                </IconButton>
              </Tooltip>
              {currentUser.group_id === 0 ||
              currentUserRoleNames.includes('Company Setup') ||
              currentUserRoleNames.includes('User Administration') ? (
                <PopupState variant='popover'>
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton
                        title='Settings'
                        aria-label='Settings'
                        sx={{ marginLeft: 'auto', color: '#FFAA00' }}
                        {...bindTrigger(popupState)}
                      >
                        <SettingsIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem>Settings</MenuItem>
                        {(currentUser.group_id === 0 ||
                          currentUserRoleNames.includes(
                            'User Administration'
                          )) && (
                          <MenuItem
                            onClick={() => {
                              dispatch(setPageTitle('User Administration'))
                              routeChange(`/users`)
                            }}
                          >
                            <ListItemIcon>
                              <ManageAccountsIcon />
                            </ListItemIcon>
                            User Administration
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            openProfileDialog()
                          }}
                        >
                          <ListItemIcon>
                            <AccountBoxIcon />
                          </ListItemIcon>
                          My Account
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              ) : (
                <Tooltip title='My Account' arrow>
                  <IconButton
                    aria-label='My Account'
                    sx={{ marginLeft: 'auto', color: '#FFAA00' }}
                    onClick={() => {
                      openProfileDialog()
                    }}
                  >
                    <AccountBoxIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title='Logout' arrow>
                <IconButton
                  aria-label='Logout'
                  sx={{ color: '#FFAA00' }}
                  onClick={() => {
                    localStorage.removeItem('apmislltk')
                    dispatch(setToken(''))
                    dispatch(setLoggedIn(false))
                    window.location.reload()
                  }}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          {showDrawer && (
            <Drawer variant='permanent' open={open} ref={drawerRef}>
              <DrawerHeader>
                <img src={AroPMISLogo} alt='AroPMIS Logo' height='35' />
                <Typography variant='h4'>
                  <strong>
                    <font style={{ color: '#9b0000' }}> &nbsp;Aro</font>
                    PMIS
                  </strong>
                </Typography>
                <IconButton
                  onClick={() => {
                    setOpen(!open)
                  }}
                  style={{ color: 'white' }}
                >
                  {open ? (
                    <Tooltip title='Shrink Menu' arrow placement='right'>
                      <ChevronLeftIcon style={{ color: 'white' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Expand Menu' arrow placement='right'>
                      <ChevronRightIcon style={{ color: 'white' }} />
                    </Tooltip>
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider style={{ background: 'grey' }} />

              <List>
                {NavItems.map((item, index) => (
                  <div key={index}>
                    <Link
                      key={index}
                      to={item.link}
                      style={{ textDecoration: 'none', color: '#ffffff' }}
                      onClick={() => {
                        dispatch(setPageTitle(item.title))
                        setOpen(false)
                        setShowDrawer(false)
                      }}
                    >
                      <Tooltip title={item.title} arrow placement='right'>
                        <ListItemButton
                          key={index}
                          hint={item.title}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.title}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </Tooltip>
                    </Link>
                    {item.items && (
                      <List
                        sx={{
                          bgcolor: '#3f3f3f',
                          borderRadius: 2,
                          margin: 1,
                        }}
                      >
                        {item.items.map((subItem, i) => {
                          return (
                            <Link
                              key={i}
                              to={subItem.link}
                              style={{
                                textDecoration: 'none',
                                color: '#8E8E8E',
                              }}
                              onClick={() => {
                                dispatch(setPageTitle(subItem.title))
                                setOpen(false)
                                setShowDrawer(false)
                              }}
                            >
                              <Tooltip
                                title={subItem.title}
                                arrow
                                placement='right'
                              >
                                <ListItemButton
                                  key={i}
                                  hint={subItem.title}
                                  sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 3 : 'auto',
                                      justifyContent: 'start',
                                    }}
                                  >
                                    {subItem.icon}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={subItem.title}
                                    sx={{ opacity: open ? 1 : 0 }}
                                  />
                                </ListItemButton>
                              </Tooltip>
                            </Link>
                          )
                        })}
                      </List>
                    )}
                  </div>
                ))}
                <Divider style={{ background: 'grey' }} />
              </List>
              <div
                style={{
                  fontSize: 10,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  color: '#FFAA00',
                }}
              >
                {company.name}
              </div>
              <List>
                <IconButton
                  onClick={() => {
                    setOpen(!open)
                  }}
                  style={{
                    color: 'white',
                    float: 'right',
                  }}
                >
                  {open ? (
                    <Tooltip title='Shrink Menu' arrow placement='right'>
                      <ChevronLeftIcon style={{ color: 'white' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Expand Menu' arrow placement='right'>
                      <ChevronRightIcon style={{ color: 'white' }} />
                    </Tooltip>
                  )}
                </IconButton>
              </List>
            </Drawer>
          )}
          <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Router />
          </Box>
          <Dialog
            open={openProfile}
            onClose={closeProfileDialog}
            TransitionComponent={Transition}
            fullWidth
          >
            <DialogTitle
              style={{
                background: '#B30000',
                color: '#ffffff',
                display: 'flex',
              }}
            >
              My Account
              <IconButton
                aria-label='close'
                style={{ color: '#ffffff', marginLeft: 'auto' }}
                onClick={() => {
                  closeProfileDialog()
                }}
              >
                <ClearIcon fontSize='small' />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TextField
                id='username'
                label='Username'
                variant='outlined'
                sx={{ marginTop: 1 }}
                value={currentUser.username}
                fullWidth
                disabled
              />
              <TextField
                id='firstName'
                label='First Name'
                variant='outlined'
                sx={{ marginTop: 1 }}
                value={currentUser.first_name}
                fullWidth
                disabled
              />
              <TextField
                id='lastName'
                label='Last Name'
                variant='outlined'
                sx={{ marginTop: 1 }}
                value={currentUser.last_name}
                fullWidth
                disabled
              />
              <TextField
                id='email'
                label='Email'
                variant='outlined'
                sx={{ marginTop: 1 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
              />
              <FormControl variant='outlined' fullWidth sx={{ marginTop: 1 }}>
                <InputLabel htmlFor='outlined-adornment-current-password'>
                  Current Password
                </InputLabel>
                <OutlinedInput
                  id='adornment-current-password'
                  type={showCurrentPassword ? 'text' : 'password'}
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value)
                  }}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showCurrentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label=' Current Password'
                />
              </FormControl>
              <FormControlLabel
                sx={{ minWidth: 200, maxWidth: 200, flexGrow: 1, marginTop: 1 }}
                control={
                  <Switch
                    checked={changePassword}
                    onChange={(e) => {
                      setChangePassword(e.target.checked)
                    }}
                  />
                }
                label='Change Password'
              />
              {changePassword && (
                <React.Fragment>
                  <FormControl variant='outlined' sx={{ marginY: 1 }} fullWidth>
                    <InputLabel htmlFor='outlined-adornment-new-password'>
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      id='adornment-new-password'
                      type={showNewPassword ? 'text' : 'password'}
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value)
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label=' New Password'
                    />
                  </FormControl>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-confirm-password'>
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id='adornment-confirm-password'
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label=' Confirm Password'
                    />
                  </FormControl>
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  closeProfileDialog()
                }}
                variant='contained'
                color='error'
              >
                Close
              </Button>
              {changes && (
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    onClick={(e) => {
                      updateProfile(e)
                    }}
                    variant='contained'
                    color='success'
                    disabled={loading}
                  >
                    Update
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'grey',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  )
}
export default DrawerNav

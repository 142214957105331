import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material'
import { theme } from './styles'
import DrawerNav from './components/DrawerNav/DrawerNav'
import { useSelector, useDispatch } from 'react-redux'

import './app.css'

import { getCompany } from './actions/company'
import { getProperties } from './actions/properties'
import { getCounties } from './actions/counties'
import { getTenantACEntries } from './actions/tenantACEntries'
import { getTenancies } from './actions/tenancies'
import { getAllProperties } from './actions/allProperties'
import { getUnits } from './actions/units'
import { getAllUnits } from './actions/allUnits'
import { getAllTenancies } from './actions/allTenancies'
import { getReceipts } from './actions/receipts'
import { getTenants } from './actions/tenants'
// import {
//   getCurrentUserRoles,
//   setCurrentUserRoleNames,
// } from './actions/currentUser'
import { getRoles } from './actions/roles'
import axios from './api/axios'
import { putSubscription } from './actions/subscription'

const App = () => {
  const loggedIn = useSelector((state) => state.loggedIn)
  const dispatch = useDispatch()

  useEffect(() => {
    if (loggedIn) {
      dispatch(getCompany())
      dispatch(getCounties())
      dispatch(getUnits())
      dispatch(getProperties())
      dispatch(getTenantACEntries())
      dispatch(getTenancies())
      dispatch(getAllTenancies())
      dispatch(getReceipts())
      dispatch(getAllUnits())
      dispatch(getAllProperties())
      dispatch(getTenants())
      dispatch(getRoles())
    }
  }, [dispatch, loggedIn])

  const [subscription, setSubscription] = useState({})

  useEffect(async () => {
    if ('serviceWorker' in navigator) {
      const handleServiceWorker = async () => {
        const register = await navigator.serviceWorker.register('/sw.js')

        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            'BIW2rvAEi6SQk_gzpKKXABU1JRfZp3Zenkib_7kzOVZ9_z_-jimzE_jYOG0ufVFJ4Cx_LVkQaUHjj-wy9FAGoFQ',
        })

        await axios
          .post('/notification/client/subscribe', { subscription })
          .then((res) => {
            setSubscription(res.data)
          })
          .catch((error) => {
            console.log(error.message)
          })
      }
      handleServiceWorker()
    }
  }, [])

  useEffect(() => {
    if (subscription.id) {
      dispatch(putSubscription(subscription))
    }
  }, [subscription])

  return (
    <ThemeProvider theme={theme}>
      <DrawerNav />
    </ThemeProvider>
  )
}

export default App

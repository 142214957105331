import { combineReducers } from 'redux'
import apiError from './apiError'
import alertMessage from './alertMessage'
import allProperties from './allProperties'
import allUnits from './allUnits'
import allTenancies from './allTenancies'
import caretakers from './caretakers'
import company from './company'
import counties from './counties'
import currentUser from './currentUser'
import loggedIn from './loggedIn'
import properties from './properties'
import propertyTypes from './propertyTypes'
import token from './token'
import units from './units'
import pageTitle from './pageTitle'
import receipts from './receipts'
import roles from './roles'
import subscription from './subscription'
import tenantACEntries from './tenantACEntries'
import tenants from './tenants'
import tenancies from './tenancies'
import currentUserRoles from './currentUserRoles'
import currentUserRoleNames from './currentUserRoleNames'

export default combineReducers({
  apiError,
  alertMessage,
  allProperties,
  allUnits,
  allTenancies,
  caretakers,
  company,
  counties,
  currentUser,
  loggedIn,
  properties,
  propertyTypes,
  token,
  units,
  pageTitle,
  roles,
  subscription,
  tenantACEntries,
  tenants,
  tenancies,
  receipts,
  currentUserRoles,
  currentUserRoleNames,
})

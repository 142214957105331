import Axios from 'axios'
// import env from 'react-dotenv'

const baseUrl = process.env.REACT_APP_API_URL

let axios = Axios.create({
  baseURL: baseUrl,
  headers: { 'Content-type': 'application/json' },
  timeout: 60000,
})

axios.interceptors.request.use(function (config) {
  let token = localStorage.getItem('apmislltk')
  config.headers['x-access-token'] = token
  return config
})

export default axios

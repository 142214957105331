import * as api from '../api'

//Action Creators
export const getAllTenancies = () => async (dispatch) => {
  try {
    const { data } = await api.fetchAllTenancies()
    dispatch({ type: 'FETCH_ALL_TENANCIES', payload: data })
  } catch (error) {
    dispatch({
      type: 'API_ERROR',
      payload: error.response.data.message,
    })
  }
}
